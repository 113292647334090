s
<template>
  <div class="container-fluid">
    <b-row>
      <b-col
        :align="responsivePhoto === 12 ? 'center' : ''" :cols="responsivePhoto"
        v-if="innerWidth"
      >
        <div v-if="client.id === null" class="product-box">
          <div class="product-img">
            <img
              v-if="src"
              class="customer-img"
              :src="src"
              height="170"
              width="170"
            >
            <d-avatar
              v-else
              :full-name="fullName"
              size="170"
              color="#DDDDDD"
              text-color="#4D4F5C"
              text-font-size="75"
            />
            <div class="product-hover">
              <ul>
                <li
                  v-if="!src"
                  class="pointer"
                  @click="uploadPhoto"
                >
                  <button class="btn" type="button">
                    <i class="icon-upload"></i>
                  </button>
                </li>
                <li
                  v-else
                  class="pointer"
                  @click="resetPhoto"
                >
                  <button
                    class="btn"
                    type="button"
                  >
                    <i class="icon-trash"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <img
              v-if="src"
              class="customer-img"
              :src="src"
              height="170"
              width="170"
            >
            <d-avatar
              v-else
              :full-name="fullName"
              size="170"
              color="#DDDDDD"
              text-color="#4D4F5C"
              text-font-size="75"
            />
          </div>
        </div>
      </b-col>
      <b-col>
        <b-row>
          <b-col
            class="mt-2"
            :cols="responsiveCols"
          >
            <b-row>
              <b-col cols="12">
                <label class="field-name-class">
                  {{ $t('views.client.new.form.firstName') }}
                </label>
              </b-col>
              <b-col cols="12">
                <d-text-field
                  v-model="client.firstName"
                  :rules="validation.firstName"
                  size="sm"
                  error-text="validation.text-input-three-characters"
                  class-name="background-light-blue-inputs"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col :cols="responsiveCols">
            <b-row class="mt-2">
              <b-col cols="12">
                <label class="field-name-class">
                  {{ $t('views.client.new.form.lastName') }}
                </label>
              </b-col>
              <b-col cols="12">
                <d-text-field
                  v-model="client.lastName"
                  :rules="validation.lastName"
                  size="sm"
                  error-text="validation.text-input-three-characters"
                  class-name="background-light-blue-inputs"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            class="mt-2"
            :cols="responsiveCols"
          >
            <b-row>
              <b-col cols="12">
                <label class="field-name-class">
                  {{ $t('views.client.new.form.phoneNumber') }}
                </label>
              </b-col>
              <b-col cols="12">
                <d-phone-number
                  v-model="client.phoneNumber"
                  :rules="validation.phoneNumber"
                  size="sm"
                  error-text="validation.phoneNumber"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col :cols="responsiveCols">
            <b-row class="mt-2">
              <b-col cols="12">
                <label class="field-name-class">
                  {{ $t('views.client.new.form.email') }}
                </label>
              </b-col>
              <b-col cols="12">
                <d-text-field
                  v-model="client.email"
                  size="sm"
                  class-name="background-light-blue-inputs"
                  :rules="null"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>

export default {
  data: () => ({
    fileToken: null,
    identityPhoto: null,
  }),
  props: {
    client: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => {
      },
    }
  },
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 700;
    },
    src() {
      return this.fileToken;
    },
    responsiveCols: function () {
      if (this.$store.getters['layout/getInnerWidth'] < 700) {
        return 12;
      } else {
        return 6;
      }
    },
    responsivePhoto: function () {
      if (this.$store.getters['layout/getInnerWidth'] < 1300 && this.$store.getters['layout/getInnerWidth'] >= 700) {
        return 4;
      }
      if (this.$store.getters['layout/getInnerWidth'] < 700) {
        return 12;
      }
      return 2;
    },
    fullName: function () {
      if (this.client.firstName && this.client.lastName) {
        return this.client.firstName + ' ' + this.client.lastName;
      } else {
        return this.$t('views.client.new.form.firstName') + ' ' + this.$t('views.client.new.form.lastName');
      }
    }
  },
  methods: {
    uploadPhoto() {
      this.$upload(this.uploaded, {entry: 'client', url: null, target: 'postClientIdentityPhoto'});
    },
    uploaded(file) {
      const url = URL.createObjectURL(file);
      this.fileToken = url;
      this.client.identityPhoto = file;
    },
    resetPhoto() {
      this.fileToken = null;
      this.client.identityPhoto = null;
    },
  },
  created() {
    if (this.client.identityPhoto) {
      this.fileToken = this.$filePath(this.client.identityPhoto.contentUrl);
    }
  }
}
</script>
<style scoped>
.customer-img {
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  object-fit: cover;
}

.product-box .product-img .product-hover {
  width: 170px;
  height: 100%;
}

@media screen and (max-width: 700px) {
  .product-box .product-img .product-hover {
    width: 100%;
  }
}
</style>

